<template>
    <v-dialog
        :width="'100%'"
        :min-width="'400px !important'"
        :max-width="'500px !important'"
        :overlay="false"
        persistent
        scrollable
        transition="dialog-bottom-transition"
        v-model="resetDialog"
    >
        <v-card>
            <v-card-title class="success white--text">
                <span>{{ $t('message.resetAllUserPasswords') }}</span>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.get"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-data-table
                    :headers="headers"
                    :items="normalUsers"
                    :items-per-page="-1"
                    class="mt-5 appic-table-light specification-table"
                    dense
                    fixed-header
                    height="400px"
                    hide-default-footer
                    item-key="User.id"
                    ref="UserTable"
                    show-select
                    selectable-key="User.id"
                    v-model="selectedUsers"
                >
                </v-data-table>
                <v-switch
                    :true-value="1"
                    :label="$t('message.sendNotificationToUsers')"
                    dense
                    hide-details="auto"
                    v-model="sendNotification"
                ></v-switch>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn
                    :loading="loading.reset"
                    color="info"
                    small
                    @click="resetAllPasswords()"
                >{{ $t('message.reset') }}</v-btn>
                <v-btn color="default" small @click="resetDialog = false" >{{$t('message.dismiss')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { api } from "Api";
import {log} from "Helpers/helpers";

export default {
    name: "AllPasswordsReset",
    props: ['dialog','users'],
    data() {
        return {
            loading: {
                get: false,
                reset: false
            },
            reset_dialog: false,
            selectedUsers: [],
            sendNotification: 1
        }
    },
    computed: {
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.user'),
                    value: 'User.title',
                    class: 'light-green lighten-3 pa-1 width-25-pct',
                    sortable: true
                },
                {
                    id: 2,
                    text: this.$t('message.login'),
                    value: 'User.email',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                }
            ]
        },
        normalUsers() {
            const users = this.users.filter((user) => {
                return user.User.userstatus_id === 1 && user.User.role !== 'ROLE_SUPERADMIN'
            })
            return users
        },
        resetDialog: {
            get() {
                return this.reset_dialog;
            },
            set(value){
                this.reset_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        superAdminUsers() {
            const users = this.users.filter((user) =>{
                return user.User.userstatus_id === 1 && user.User.role === 'ROLE_SUPERADMIN'
            })
            return users
        }
    },
    methods: {
        async resetAllPasswords() {
            if(this.selectedUsers == null){
                this.$toast.error(this.$t('message.errors.noUsersSelected'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            } else {
                if (await this.$root.$confirm(this.$t('message.resetAllUserPasswords'), this.$t('message.confirmations.continueResetAllPasswordsAction'), {color: 'orange'})) {
                    let users = []
                    this.selectedUsers.forEach(su => {
                        users.push( { email: su.User.email } )
                    })
                    let promise = new Promise((resolve, reject) => {
                        api
                            .put("/users/passwords", {
                                selectedUsers: users,
                                sendNotification: this.sendNotification
                            })
                            .then(response => {
                                resolve('done')
                            })
                            .catch(error => {
                                reject(error)
                            })
                    })
                    this.loading.reset = true
                    promise
                        .then(response => {
                            if (response == 'done') {
                                this.$toast.success(this.$t('message.successes.userPasswordReset'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.reset = false
                                this.$emit('reset-done')
                                this.resetDialog = false
                            } else {
                                this.$toast.error(this.$t('message.errors.userPasswordNotReset'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.reset = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.userPasswordNotReset'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.reset = false
                        })
                }
            }
        }
    },
    watch: {
        dialog(value) {
            this.reset_dialog = value
        },
        normalUsers(value) {
            this.selectedUsers = value
        }
    }
}
</script>

<style scoped>
.v-data-table::v-deep .v-data-table-header th {
    white-space: nowrap;
}
.v-data-table::v-deep .v-data-table-header tr th:first-child {
    background-color: #c5e1a5 !important;
}
</style>